.color {
    &-hex {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-box {
        display: block;
        height: 18px;
        width: 18px;
        margin-right: 6px;
    }

    &-form {
        &-hex {
            display: flex;
            flex-direction: row;
            align-items: stretch;

            .form-select {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            .form-select:focus {
                border-color: #ced4da;
                box-shadow: none;
            }
        }

        &-hex:focus-within {
            border-color: #9ac8ee;
            border-radius: 0.25rem;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(52, 144, 220, 0.25);
        }

        &-box {
            display: block;
            width: 31px;
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            border-top: 1px solid #ced4da;
            border-bottom: 1px solid #ced4da;
            border-left: 1px solid #ced4da;
        }

        &-label {
            transform: scale(0.85) translateY(-0.5rem) translateX(45px) !important;
        }
    }
}

.form-floating .color-form-box {
    display: block;
    height: 58px;
    width: 40px;
}
