a {
    text-decoration: none;
}

a:not(.btn, .list-group-item, .rating-star, .card, .recipe-tag-link):hover {
    text-decoration: underline;
}

a.card:hover {
    text-decoration: none;
}

@media (prefers-color-scheme: light) {
    a:not(.alert-link, .rating-star, .btn) {
        color: black !important;
    }

    a.card:hover {
        background-color: $primary-white;
    }
}

@media (prefers-color-scheme: dark) {
    a:not(.alert-link, .rating-star), a:not(.alert-link, .rating-star):hover, button, button:hover {
        color: white !important;
    }

    a.card:hover {
        background-color: $primary-gray;
    }
}
