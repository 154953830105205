.navbar {
    min-height: 64px;

    .navbar-toggler {
        height: 64px;
    }
}

.navbar-nav {
    align-self: stretch;

    .nav-item {
        padding: 0;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        &.dropdown {
            padding: 1rem 0.5rem;
        }

        .nav-link {
            padding: 1.2rem;
        }
    }

    a, a:hover {
        text-decoration: none !important;
    }
}

@include media-breakpoint-down(md) {
    .navbar-brand {
        position: absolute;
        left: calc(50% - 100px);
        top: 0;
    }

    .navbar-user-menu {
        position: absolute;
        right: 24px;
    }
}

@media (prefers-color-scheme: light) {
    .navbar {
        background-color: white;
    }

    .navbar-brand {
        color: black;
    }

    .navbar-dark .navbar-nav .show > .nav-link,
    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .nav-link.active {
        color: black;
    }

    .navbar-nav .nav-item:not(.user-menu):hover, .navbar-nav .nav-item:not(.user-menu):focus {
        background-color: $primary-white;
    }

    .navbar-nav .nav-link {
        color: rgba(0, 0, 0, 0.75);
    }
}

@media (prefers-color-scheme: dark) {
    .navbar {
        background-color: $dark-gray !important;
    }

    .navbar-brand {
        color: white;
    }

    .navbar-dark .navbar-nav .show > .nav-link,
    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .nav-link.active {
        color: white;
    }

    .navbar-nav .nav-item:not(.user-menu):hover, .navbar-nav .nav-item:not(.user-menu):focus {
        background-color: $primary-gray !important;
    }

    .navbar-nav .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }

    nav .pagination {
        .page-link {
            background-color: $dark-gray !important;
            border: 1px solid $border-gray !important;
        }

        .page-link:hover {
            background-color: $primary-gray !important;
        }

        .page-item.active .page-link {
            background-color: $blue !important;
        }
    }
}
