.navbar {
    .dropdown {
        position: static;
        .dropdown-toggle::after {
            display: none;
        }
        .dropdown-menu {
            position: absolute;
            top: 100%;
            right: 24px;
            margin: 0;
            .dropdown-item {
                padding-top: 0.9rem !important;
                padding-bottom: 0.9rem !important;
            }
        }
        a, a:hover {
            text-decoration: none !important;
        }
    }
    .dropdown:hover .dropdown-menu {
        display: block;
    }
    .dropdown-menu:hover {
        display: block;
    }

    @include media-breakpoint-down(md) {
        .dropdown .dropdown-menu {
            right: 0;
        }
    }

    @include media-breakpoint-up(sm) {
        .dropdown .dropdown-menu {
            width: auto;
        }
    }
}
@media (prefers-color-scheme: dark) {
    .dropdown-menu {
        background-color: #283140;
    }
    .dropdown-item {
        color: white;
    }
    .dropdown-item:hover, .dropdown-item:focus {
        color: white !important;
        background-color: $primary-gray !important;
    }
}

