.cookies {
    &__alert {
        @media (prefers-color-scheme: dark) {
            background-color: #374358 !important;
            border-color: $border-gray !important;
        }
    }

    &__title, &__intro, &__label, &__info {
        @media (prefers-color-scheme: dark) {
            color: $primary-white !important;
        }
    }

    &__category .cookies__box:after {
        @media (prefers-color-scheme: dark) {
            background-color: $gray-500 !important;
        }
    }

    &__category input:disabled+.cookies__box:after {
        @media (prefers-color-scheme: dark) {
            opacity: 0.5 !important;
        }
    }

    &__category input:checked+.cookies__box:after {
        background-color: $primary !important;
    }

    &Btn--essentials .cookiesBtn__link {
        background: none !important;
        border-color: $secondary !important;
        color: #2c2e30 !important;

        &:hover {
            background: $secondary !important;
            color: inherit !important;
        }
    }

    &Btn--accept .cookiesBtn__link,
    &__customize .cookiesBtn__link {
        background: $success !important;
        border-color: $success !important;
    }

    &Btn--update .cookiesBtn__link {
        background: none;
        border: 1px solid $secondary;
        border-radius: 4px;
        color: #2c2e30;
        cursor: pointer;
        display: block;
        font: inherit;
        font-size: 14px;
        font-weight: 600;
        line-height: 1em;
        margin: 0;
        overflow: hidden;
        padding: .86em 1em;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        transition: opacity .2s ease-out;
        white-space: nowrap;
    }
}
