.card.notification {
    border-width: 3px !important;
    border-color: $primary !important;
    width: 100%;
    margin-bottom: 1rem;
}

@media (prefers-color-scheme: dark) {
    .card {
        background-color: $dark-gray;
        border-color: $dark-gray !important;
    }

    .card-header {
        background-color: $primary-gray;
        color: white;
    }

    .card-body {
        background-color: $dark-gray;
        color: white;
    }
}
