.h-4 {
    height: 1rem;
}
.h-8 {
    height: 2rem;
}
.h-12 {
    height: 3rem;
}
.h-16 {
    height: 4rem;
}
.h-32 {
    height: 8rem;
}
.h-64 {
    height: 16rem;
}
.h-128 {
    height: 32rem;
}
.w-4 {
    width: 1rem;
}
.w-8 {
    width: 2rem;
}
.w-12 {
    width: 3rem;
}
.w-16 {
    width: 4rem;
}
.w-32 {
    width: 8rem;
}
.w-64 {
    width: 16rem;
}

@media (min-width: 640px) {
    .container {
        max-width:640px
    }

    .navbar-nav {
        .nav-item.dropdown {
            padding: 1rem;
        }
        .nav-item .nav-link {
            padding: 1.5rem;
        }
    }

    .cookie-popup {
        left: 15%;
        top: 20%;
        max-width: 70%;

        padding: 40px;
    }

    .sm\:flex-row {
        flex-direction:row
    }

    .sm\:mt-0 {
        margin-top:0
    }

    .sm\:w-1\/2 {
        width:50%
    }

    .sm\:w-1\/3 {
        width:33.333333%
    }

    .sm\:w-2\/3 {
        width:66.666667%
    }
}

@media (min-width: 768px) {
    .container {
        max-width:768px
    }

    .md\:space-y-0 > :not(template) ~ :not(template) {
        --space-y-reverse: 0;
        margin-top: calc(0px *(1 - var(--space-y-reverse)));
        margin-bottom:calc(0px * var(--space-y-reverse))
    }

    .md\:space-x-4 > :not(template) ~ :not(template) {
        --space-x-reverse: 0;
        margin-right: calc(1rem * var(--space-x-reverse));
        margin-left:calc(1rem *(1 - var(--space-x-reverse)))
    }

    .md\:block {
        display:block
    }

    .md\:inline {
        display:inline
    }

    .md\:flex {
        display:flex
    }

    .md\:hidden {
        display:none
    }

    .md\:flex-row {
        flex-direction:row
    }

    .md\:text-base {
        font-size:.925rem
    }

    .md\:text-lg {
        font-size:1.125rem
    }

    .md\:text-2xl {
        font-size:1.5rem
    }

    .md\:text-3xl {
        font-size:1.875rem
    }

    .md\:text-4xl {
        font-size:2.25rem
    }

    .md\:m-4 {
        margin:1rem
    }

    .md\:mx-0 {
        margin-left: 0;
        margin-right:0
    }

    .md\:mb-0 {
        margin-bottom:0
    }

    .md\:-mt-6 {
        margin-top:-1.5rem
    }

    .md\:py-0 {
        padding-top: 0;
        padding-bottom:0
    }

    .md\:py-1 {
        padding-top: .25rem;
        padding-bottom:.25rem
    }

    .md\:px-2 {
        padding-left: .5rem;
        padding-right:.5rem
    }

    .md\:px-4 {
        padding-left: 1rem;
        padding-right:1rem
    }

    .md\:w-1\/2 {
        width:50%
    }

    .md\:w-1\/3 {
        width:33.333333%
    }

    .md\:w-3\/4 {
        width:75%
    }

    .md\:w-2\/5 {
        width:40%
    }

    .md\:w-3\/5 {
        width:60%
    }

    .md\:lines-1 {
        max-lines: 1;
        line-clamp: 1;
        overflow: hidden;
        block-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient:vertical
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 980px;
    }

    .cookie-popup {
        left: 20%;
        max-width: 60%;
        max-height: 70%;

        padding: 40px;
    }

    .lg\:border-t-0 {
        border-top-width:0
    }

    .lg\:border-b-0 {
        border-bottom-width:0
    }

    .lg\:border-l-2 {
        border-left-width:2px
    }

    .lg\:border-r {
        border-right-width:1px
    }

    .lg\:border-l {
        border-left-width:1px
    }

    .lg\:block {
        display:block
    }

    .lg\:inline-block {
        display:inline-block
    }

    .lg\:inline {
        display:inline
    }

    .lg\:flex {
        display:flex
    }

    .lg\:hidden {
        display:none
    }

    .lg\:flex-row {
        flex-direction:row
    }

    .lg\:flex-col {
        flex-direction:column
    }

    .lg\:flex-wrap {
        flex-wrap:wrap
    }

    .lg\:flex-no-wrap {
        flex-wrap:nowrap
    }

    .lg\:flex-1 {
        flex:1 1 0%
    }

    .lg\:order-3 {
        order:3
    }

    .lg\:h-1 {
        height:.25rem
    }

    .lg\:h-12 {
        height:3rem
    }

    .lg\:h-16 {
        height:4rem
    }

    .lg\:h-32 {
        height:8rem
    }

    .lg\:h-64 {
        height:16rem
    }

    .lg\:h-128 {
        height: 32rem;
    }

    .lg\:h-auto {
        height:auto
    }

    .lg\:text-xs {
        font-size:.75rem
    }

    .lg\:text-sm {
        font-size:.875rem
    }

    .lg\:text-base {
        font-size:.925rem
    }

    .lg\:text-lg {
        font-size:1.125rem
    }

    .lg\:text-xl {
        font-size:1.25rem
    }

    .lg\:text-2xl {
        font-size:1.5rem
    }

    .lg\:text-3xl {
        font-size:1.875rem
    }

    .lg\:text-4xl {
        font-size:2.25rem
    }

    .lg\:text-5xl {
        font-size:3rem
    }

    .lg\:m-4 {
        margin:1rem
    }

    .lg\:my-0 {
        margin-top: 0;
        margin-bottom:0
    }

    .lg\:mx-2 {
        margin-left: .5rem;
        margin-right:.5rem
    }

    .lg\:mx-4 {
        margin-left: 1rem;
        margin-right:1rem
    }

    .lg\:-mx-4 {
        margin-left: -1rem;
        margin-right:-1rem
    }

    .lg\:mt-0 {
        margin-top:0
    }

    .lg\:mb-0 {
        margin-bottom:0
    }

    .lg\:ml-0 {
        margin-left:0
    }

    .lg\:mt-4 {
        margin-top:1rem
    }

    .lg\:mr-4 {
        margin-right:1rem
    }

    .lg\:mr-6 {
        margin-right:1.5rem
    }

    .lg\:ml-8 {
        margin-left:2rem
    }

    .lg\:p-2 {
        padding:.5rem
    }

    .lg\:p-4 {
        padding:1rem
    }

    .lg\:p-6 {
        padding:1.5rem
    }

    .lg\:py-0 {
        padding-top: 0;
        padding-bottom:0
    }

    .lg\:py-1 {
        padding-top: .25rem;
        padding-bottom:.25rem
    }

    .lg\:py-5 {
        padding-top: 1.25rem;
        padding-bottom:1.25rem
    }

    .lg\:px-12 {
        padding-left: 3rem;
        padding-right:3rem
    }

    .lg\:pl-3 {
        padding-left:.75rem
    }

    .lg\:pr-4 {
        padding-right:1rem
    }

    .lg\:pl-6 {
        padding-left:1.5rem
    }

    .lg\:w-2 {
        width:.5rem
    }

    .lg\:w-4 {
        width: 1rem;
    }
    .lg\:w-8 {
        width: 2rem;
    }
    .lg\:w-12 {
        width: 3rem;
    }
    .lg\:w-16 {
        width: 4rem;
    }
    .lg\:w-32 {
        width: 8rem;
    }
    .lg\:w-64 {
        width: 16rem;
    }
    .lg\:w-128 {
        width: 32rem;
    }

    .lg\:w-auto {
        width:auto
    }

    .lg\:w-1\/2 {
        width:50%
    }

    .lg\:w-1\/3 {
        width:33.333333%
    }

    .lg\:w-2\/3 {
        width:66.666667%
    }

    .lg\:w-1\/4 {
        width:25%
    }

    .lg\:w-3\/4 {
        width:75%
    }

    .lg\:w-2\/5 {
        width:40%
    }

    .lg\:w-4\/5 {
        width:80%
    }

    .lg\:w-full {
        width:100%
    }

    .lg\:w-aside-article {
        width:calc(100% - 320px)
    }

    .lg\:w-aside {
        width:320px
    }
}

@media (min-width: 1140px) {
    .container {
        max-width: 1140px;
    }
    .cookie-popup {
        left: 25%;
        max-width: 50%;
    }

    .xl\:h-12 {
        height:3rem
    }
    .xl\:h-16 {
        height:4rem
    }
    .xl\:h-32 {
        height:8rem
    }
    .xl\:h-48 {
        height:12rem
    }
    .xl\:h-64 {
        height:16rem
    }
    .xl\:h-128 {
        height: 32rem;
    }

    .xl\:w-12 {
        width: 3rem;
    }
    .xl\:w-16 {
        width: 4rem;
    }
    .xl\:w-32 {
        width: 8rem;
    }
    .xl\:w-48 {
        width: 12rem;
    }
    .xl\:w-64 {
        width: 16rem;
    }
    .xl\:w-128 {
        width: 32rem;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
    .cookie-popup {
        left: 33%;
        max-width: 33%;
    }

    .xxl\:h-12 {
        height:3rem
    }
    .xxl\:h-16 {
        height:4rem
    }
    .xxl\:h-32 {
        height:8rem
    }
    .xxl\:h-48 {
        height:12rem
    }
    .xxl\:h-64 {
        height:16rem
    }
    .xxl\:h-128 {
        height: 32rem;
    }

    .xxl\:w-12 {
        width: 3rem;
    }
    .xxl\:w-16 {
        width: 4rem;
    }
    .xxl\:w-32 {
        width: 8rem;
    }
    .xxl\:w-48 {
        width: 12rem;
    }
    .xxl\:w-64 {
        width: 16rem;
    }
    .xxl\:w-128 {
        width: 32rem;
    }
}

@media (min-width : 1824px) {
    .container {
        max-width: 1800px;
    }
    .cookie-popup {
        left: 38%;
        max-width: 24%;
    }
}

