.recipe-heading {
    overflow-wrap: anywhere;
    font-weight: bold;
    font-size: 1.25rem;

    @media (min-width: 768px) {
        font-size: calc(1.275rem + 0.3vw);
    }
}

.recipe-link {
    padding: 1rem;

    a:hover {
        text-decoration: none;
    }

    &:hover {
        @media (prefers-color-scheme: light) {
            background-color: #fff !important;
        }

        @media (prefers-color-scheme: dark) {
            background-color: $primary-gray !important;
        }
    }
}

.recipe-image-container {
    position: relative;
}

.image-wrapper {
    overflow: hidden;
    position: relative;
}
.image-wrapper img {
    height: 100%;
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.recipe-tag {
    &-list {
        display: flex;
        flex-wrap: wrap;

        @media(max-width: 420px) {
            display: none;
        }

        &-teaser {
            max-height: calc(60px + 1rem);
            overflow: hidden;

            @media(max-width: 1024px) {
                max-height: calc(30px + 0.5rem);
                overflow: hidden;
            }
        }
    }

    &-link:hover {
        text-decoration: none;

        @media (prefers-color-scheme: light) {
            background-color: $primary-white;
        }

        @media (prefers-color-scheme: dark) {
            background-color: $primary-gray;
        }
    }

    &-item {
        flex: 0 0 auto;
        width: auto;
        padding: 2px 5px;
        margin-right: 10px;
        margin-bottom: 0.5rem;
        border: 1px solid $dark-gray;
        border-radius: .25rem;
        font-weight: normal;

        @media (prefers-color-scheme: dark) {
            border-color: $body-bg;
        }
    }
}

.recipe-image {
    height: 16rem;
    width: auto;

    &-lightbox:hover {
        cursor: zoom-in;
    }

    @media (max-width: 420px) {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
    }
}

.recipe-image-list {
    height: auto;
    width: 100%;

    @media (max-width: 420px) {
        margin-bottom: 1rem;
    }
}

.recipe-image-modal {
    width: -moz-fit-content;
    width: fit-content;
    max-width: 80vw;
    max-height: 90vh;
    margin: 1.75rem auto;
}

.recipe-image-modal-img {
    min-height: 40vh;
    height: auto;
    max-height: 90vh;
    min-width: 40vw;
    width: auto;
    max-width: 100%;
}

.recipe-image-edit {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: hidden;
}

.recipe-image-edit-overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.recipe-image-create {
    margin-top: 1rem;
    height: 8rem;

    @media (max-width: 420px) {
        width: 100%;
        height: auto;
        margin-bottom: 0;
    }
}

.recipe-image-container:hover .recipe-image-edit {
    opacity: 0.3;
}

.recipe-image-container:hover .recipe-image-edit-overlay {
    opacity: 1;
}

.rating-star {
    color: $warning !important;
}
.rating-star:hover {
    text-decoration: none;
}

.btn:hover.favorite {
    color: red !important;
}
.btn.favorited {
    color: red !important;
}

.btn.recipe-create-submit {
    margin-left: 0.25rem;
}

.recipe-servings {
    max-width: 100px;
}

.recipe-large-card {
    height: 100%;

    &__col {
        &-image {
            flex: 0 0 auto;
            width: auto;
            margin-bottom: 0;

            @media (max-width: 420px) {
                flex: 0 0 auto;
                width: 100%;
                margin-bottom: 0.5rem;
            }
        }

        &-text {
            flex: 1 0 0;
            margin-bottom: 0;

            @media (max-width: 420px) {
                flex: 0 0 auto;
                width: 100%;
            }
        }
    }

    &__image {
        width: 8rem;
        height: 8rem;

        @media (max-width: 420px) {
            width: 100%;
            min-height: 16rem;
            height: 100%;
        }

        @media (min-width: 1024px) {
            width: 16rem;
            height: 16rem;
        }

        img {
            width: 8rem;
            height: 8rem;

            @media (max-width: 420px) {
                width: 100%;
                height: 100%;
            }

            @media (min-width: 1024px) {
                width: 16rem;
                height: 16rem;
            }
        }
    }

    &.loading {
        height: 160px;
        background:
            linear-gradient(0.25turn, transparent, $primary-white, transparent) -60% 0 no-repeat,
            linear-gradient($white, $white) 0 0 no-repeat;
        background-size: 40% 100%, 100% 100%;
        animation: loading 1.5s infinite;

        @media (prefers-color-scheme: dark) {
            background:
                linear-gradient(0.25turn, transparent, $primary-gray, transparent) -60% 0 no-repeat,
                linear-gradient($dark-gray, $dark-gray) 0 0 no-repeat;
        }

        @media (max-width: 420px) {
            height: 360px;
            background-size: 60% 100%, 100% 100%;
            background-position: -150% 0, 0 0;
        }

        @media (min-width: 1400px) {
            height: 290px;
        }
    }
}
