footer {
    width: 100%;
    margin-top: 4rem;

    .footer-separator {
        position: relative;
        width: 100%;
        height: 4rem;
        margin-bottom: -1px;
        background-position: bottom;
        background-size: contain;
        background-repeat:repeat-x
    }

    @media (prefers-color-scheme: light) {
        a {
            font-weight: 500;
        }

        .footer-separator {
            background-image: url("/img/footer-background-light.svg");
        }

        .footer-bg {
            background-color: white !important;
            color: black;
            box-shadow: 0 0.5rem 3.5rem rgba(0, 0, 0, 0.15) !important;
        }

        .text-primary {
            color: $gray-600 !important;
        }
    }

    @media (prefers-color-scheme: dark) {
        .footer-separator {
            background-image: url("/img/footer-background-dark.svg");
        }

        .footer-bg {
            background-color: $dark-gray !important;
            color: white;
        }

        .text-primary {
            color: $gray-600 !important;
        }
    }
}
