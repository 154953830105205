// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Toastr
@import '~toastr/toastr';

// Croppie
@import '~croppie/croppie.css';

//Fonts
$fa-font-path: "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

// Variables
@import 'variables';

// Common
@import "common";

// Misc
@import "misc/animations";
@import "misc/spacing";
@import "misc/sizing";
@import "misc/font";

// Page
@import "page/nav";
@import "page/a";
@import "page/breadcrumb";
@import "page/card";
@import "page/modal";
@import "page/footer";

// Elements
@import "elements/home";
@import "elements/alert";
@import "elements/colors";
@import "elements/cookie";
@import "elements/dropdown";
@import "elements/notification";
@import "elements/recipe";
@import "elements/searchbar";
@import "elements/tags";

