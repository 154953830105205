@media (prefers-color-scheme: light) {
    .breadcrumb-item {
        color: black !important;
        margin-bottom: 0.25rem !important;
    }

    .breadcrumb-item.active {
        color: #666666 !important;
    }

    .breadcrumb-item a {
        color: black !important;
        text-decoration: none;
    }

    .breadcrumb-item a:hover {
        text-decoration: underline;
    }
}

@media (prefers-color-scheme: dark) {
    .breadcrumb-item {
        color: white !important;
        margin-bottom: 0.25rem !important;
    }

    .breadcrumb-item.active {
        color: #999999 !important;
    }

    .breadcrumb-item a {
        color: white !important;
        text-decoration: none;
    }

    .breadcrumb-item a:hover {
        text-decoration: underline;
    }
}
