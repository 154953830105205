// Custom styling
html {
    height: 100%;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left:1rem
}

form .btn {
    font-weight: 600;
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
}

.flex-1-auto {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}

.rounded-full {
    border-radius: 50% !important;
}

.badge-counter {
    position: relative;
    -webkit-transform: scale(.7);
    transform: scale(.7);
    -webkit-transform-origin: top right;
    transform-origin: top right;
    right: .75rem;
    top: -.4rem;
    padding: .25em .4em;
}

.profile-image-link {
    padding: 0 0.5rem 0 0;
    flex: 0 1 0;
}

.image-square-fit {
    object-fit: cover;
}

@media (prefers-color-scheme: light) {
    html {
        background-color: $body-bg !important;
        color: black;
    }

    body {
        background-color: $body-bg !important;
        color: black;
    }

    .text-primary {
        color: black !important;
    }
}

@media (prefers-color-scheme: dark) {
    html {
        background-color: $body-bg-dark !important;
        color: white;
    }

    body {
        background-color: $body-bg-dark !important;
        color: white;
    }

    .text-primary {
        color: white !important;
    }

    .form-floating label {
        color: black;
    }

    form .btn {
        color: white;
        text-decoration: none;
    }

    .list-group-item {
        background-color: $dark-gray;
        border-right: none !important;
        border-left: none !important;
        border-top-color: $border-gray !important;
        border-bottom-color: $border-gray !important;
    }

    .list-group-item:not(.active):hover {
        background-color: $primary-gray !important;
        color: white !important;
    }

    .list-group-item-action {
        color: white;
        font-weight: 700;
    }

    .table > :not(caption) > * > * {
        background-color: $dark-gray;
    }

    .table-striped > tbody > tr:nth-of-type(odd) > * {
        background-color: $primary-gray;
    }

    .table > thead > tr > th, table > tbody > tr, table > tbody > tr > td {
        color: white !important;
    }
}
