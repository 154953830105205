// Margins
.mt-6 {
    margin-top: 1.5rem;
}

.ml-1 {
    margin-left: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-auto {
    margin-right: auto !important;
}

// Padding
.pr-0 {
    padding-right: 0;
}

.pl-8, .px-8, .p-8 {
    padding-left: 2rem;
}

.pr-8, .px-8, .p-8 {
    padding-right: 2rem;
}

.pt-8, .py-8, .p-8 {
    padding-top: 2rem;
}

.pb-8, .py-8, .p-8 {
    padding-bottom: 2rem;
}

.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
