#user-alert {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 1rem 0.5rem;

    .alert {
        margin: 0 auto;
        padding: 0.5rem 1rem;
    }
}
