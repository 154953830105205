.home {
    &-section {
        &-header-row {
            justify-content: space-between;
            align-items: flex-end;
        }

        &-body-row {
            --bs-gutter-x: 1rem;
        }

        &-header {
            flex: 0 0 auto;
            width: auto;
            margin-top: 0.5rem;
        }

        &-more {
            flex: 0 0 auto;
            width: auto;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
}
