.cookie-backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9998;
    background: black;
    opacity: 0.4;
}

.cookie-popup {
    opacity: 1.0;
    position: fixed;
    left: 5%;
    top: 5%;
    z-index: 9999;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    padding: 20px;
    margin: 0;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;



    &-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;

        word-wrap: break-word;
    }

    &-buttons {
        margin-top: 1.5rem;
        display: flex;
        justify-content: end;
    }
}

@media (min-width: 1025px) {
    .cookie-popup {
        max-width: 600px;
        left: calc(50% - 300px);
    }
}

@media (max-width: 360px) {
    .cookie-popup {
        width: 90%;
        max-width: 90%;
        height: 90%;
        max-height: 90%;
        overflow: hidden;

        &-content {
            display: block;
            position: relative;
            overflow-y: scroll;
            width: 100%;
            height: 100%;
        }

        &-buttons {
            justify-content: center;
        }
    }

}

@media (prefers-color-scheme: light) {
    .cookie-popup {
        background-color: white;
        color: black;
    }
}

@media (prefers-color-scheme: dark) {
    .cookie-popup {
        background-color: #374358;
        color: white;
    }
}
