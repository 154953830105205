.font-bold {
    font-weight: 700;
}

.font-semibold {
    font-weight: 600;
}

.leading-normal {
    line-height: 1.5;
}

.line-1,
.line-2,
.line-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.line {
    &-1 {
        max-lines: 1;
        line-clamp: 1;
        -webkit-line-clamp: 1;
    }

    &-2 {
        max-lines: 2;
        line-clamp: 2;
        -webkit-line-clamp: 2;
    }

    &-3 {
        max-lines: 3;
        line-clamp: 3;
        -webkit-line-clamp: 3;
    }
}

// Article Fonts
.article-content {
    line-height: 1.5;
    font-size: 1.125rem;
    padding-left: 1rem;
    padding-right:1rem
}

.article-content h1,
.article-content h2,
.article-content h3 {
    font-weight: 700;
}

.article-content h1,
.article-content h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.article-content h3 {
    margin-bottom: .5rem;
}

.article-content h1 {
    font-size: 1.875rem;
}

.article-content h2 {
    font-size: 1.5rem;
}

.article-content h3 {
    font-size: 1.25rem;
}

.article-content p,
.article-content ul {
    margin-bottom: 1rem;
}

.article-content b {
    font-weight: 700;
}

.article-content a[href] {
    color: var(--color-primary-500);
    font-weight: 600;
}

.article-content a[href]:hover {
    color: var(--color-primary-600);
}

.article-content ul {
    padding-left: 1.5rem;
    list-style-type: disc;
}

.article-content figure {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.article-content figure a,
.article-content figure img {
    margin-left: auto;
    margin-right: auto;
}

.article-content figure img {
    display: inline-block;
    border-radius: .17rem;
}

.article-content figure figcaption {
    display: block;
    width: 100%;
    font-size: .875rem;
    margin-top: 1rem;
    color: var(--color-gray-600);
}
